import { Expose, plainToClass, Transform, Type } from "class-transformer";
import { Education } from "../../Entities/Education";
import { MedicalRegistration } from "../../Entities/MedicalRegistration";
import { ProfileLinks } from "../../Entities/ProfileLinks";
import { Schedule } from "../../Entities/Schedule";
import { DoctorPracticeRelationship } from "../../Relationships/DoctorPracticeRelationship";
import { ContactNumber } from "../Person/ContactNumber";
import { Email } from "../Person/Email";
import { PracticeListingProfile } from "../../Entities/PracticeListingProfile";
import { OnlineConsultationSettings } from "../../Entities/OnlineConsultationSettings";
import { AssignProfile } from "../../Entities/AssignProfile";
import { DocumentInfo } from "../../Entities/DocumentInfo";
import { AssociatedDoctorsWallet } from "../../Entities/AssociatedDoctorsWallet";
import { displayTextForPracticesWithInPersonConsultationBookingOff, displayTextForPracticesWithInPersonConsultationBookingOn, getContactNumbersWithExtension, getFirstName, getLastName, getMasterSpecialization, getMasterSpecializationDisplayValue, getOfflineConsultationTimings, getOnlineConsultationTimings,getAllContactNumbers, getPrimaryContactNumbers, getPublicContactNumbers, getPublicContactNumbersWithExtension, getPublicContactNumbersWithoutExtension, specializationDisplayText, getAllWhatsAppContactNumbers } from "./DoctorListingProfileHelper";
import { ProviderLogin } from "../ProviderLogin/ProviderLogin";
import { ListedIn } from '../../Entities/ListedIn';
import { ReasonOfVisit } from '../../Entities/ReasonOfVisit';
import { PayoutSharing } from "../../Entities/PayoutSharing";
import { OnlineConsultationPlatform } from "../../Enums/Enums";
import { OtherInfo } from "./OtherInfo";
import { DayjsHelper } from "../../Helpers/dayjsHelper";
import { BooleanAction } from "../../Protocols/BooleanAction";
import { ActionDoneBy } from "../../Protocols/ActionDoneBy";
export class DoctorListingProfile {
  @Expose() id: string;
  @Expose() name: string;
  @Expose() gender: string;
  @Expose() pronoun: string;
  @Expose() salutation: string;

  @Expose()
  specializations: string[]; // Map<string,number>;

  @Expose()
  @Transform(
    (value) => {
      if (value) {
        let map = new Map<string, ListedIn>();
        for (let entry of Object.entries(value)) {
          map.set(entry[0], plainToClass(ListedIn, entry[1]));
        }
        return map;
      } else {
        return new Map<string, ListedIn>();
      }
    },
    { toClassOnly: true }
  )
  listedIn: Map<string, ListedIn>;

  @Expose()
  @Transform(
    (value) => {
      if (value) {
        let map = new Map<string, ReasonOfVisit>();
        for (let entry of Object.entries(value)) {
          map.set(entry[0], plainToClass(ReasonOfVisit, entry[1]));
        }
        return map;
      } else {
        return new Map<string, ReasonOfVisit>();
      }
    },
    { toClassOnly: true }
  )
  reasonsOfVisit: Map<string, ReasonOfVisit>;

  @Expose()
  reasonsOfVisitDisplayText: string;

  @Expose()
  internTags: string[];

  @Expose() experienceInYears: string;
  @Expose() experienceSavedOn: string;
  @Expose() type: string;
  @Expose() email: string;
  @Expose() city: string;
  @Expose() videoUrl: string;
  @Expose() photoUrl: string;

  @Type(() => OtherInfo)
  @Expose()
  otherInfo: OtherInfo = new OtherInfo();

  @Type(() => ContactNumber)
  @Expose()
  publicContactNumber: ContactNumber = new ContactNumber();

  @Type(() => PayoutSharing)
  @Expose() 
  payoutSharing: PayoutSharing

  @Type(() => ContactNumber)
  @Expose()
  primaryContactNumber: ContactNumber = new ContactNumber();

  @Expose()
  @Type(() => ContactNumber)
  whatsappContactNumber: ContactNumber = new ContactNumber();

  @Expose()
  @Type(() => BooleanAction)
  hasChatModuleAccess: BooleanAction = new BooleanAction();

  @Expose() handlesCovid19Cases: boolean;

  @Expose()
  @Transform(
    (value) => {
      let masterMap: Map<string, Map<string, ContactNumber>> = new Map<
        string,
        Map<string, ContactNumber>
      >();
      if (value) {
        for (let entryMap of Object.entries(value)) {
          let map = new Map<string, ContactNumber>();
          if (masterMap.has(entryMap[0])) {
            map = masterMap.get(entryMap[0]);
          }
          for (let c of Object.entries(entryMap[1])) {
            map.set(c[0], plainToClass(ContactNumber, c[1]));
          }
          masterMap.set(entryMap[0], map);
        }
      }
      return masterMap;
    },
    { toClassOnly: true }
  )
  contactNumbers: Map<string, Map<string, ContactNumber>> = new Map<
    string,
    Map<string, ContactNumber>
  >();

  @Expose()
  @Transform(
    (value) => {
      if (value) {
        let map = new Map<string, Email>();
        for (let entry of Object.entries(value)) {
          map.set(entry[0], plainToClass(Email, entry[1]));
        }
        return map;
      } else {
        return new Map<string, Email>();
      }
    },
    { toClassOnly: true }
  )
  emails: Map<string, Email> = new Map<string, Email>();

  @Expose()
  @Type(() => Education)
  education: Education[];

  @Expose()
  @Type(() => MedicalRegistration)
  registration: MedicalRegistration[];

  @Expose() isProfileClaimed: boolean = false;
  @Expose() isMedicalRegistrationVerified: boolean = false;
  @Expose() isRCILicenseVerified: boolean = false;

  @Expose() listedInCities: string[];
  @Expose() languages: string[];
  @Expose() about: string;

  @Type(() => OnlineConsultationSettings)
  @Expose()
  onlineConsultationConfig: OnlineConsultationSettings = new OnlineConsultationSettings();

  @Type(() => ProviderLogin)
  @Expose()
  providerLogin: ProviderLogin = new ProviderLogin();

  @Expose()
  blockedSlots: any;

  @Expose()
  openSlots: any;

  @Expose()
  @Type(() => Schedule)
  schedule: Schedule[];

  @Expose()
  @Type(() => PracticeListingProfile)
  practices: PracticeListingProfile[];

  @Expose()
  @Transform(
    (value) => {
      if (value) {
        let map = new Map<string, DoctorPracticeRelationship>();
        for (let entry of Object.entries(value))
          map.set(entry[0], plainToClass(DoctorPracticeRelationship, entry[1]));
        return map;
      } else {
        return new Map<string, DoctorPracticeRelationship>();
      }
    },
    { toClassOnly: true }
  )
  associatedPractices: Map<string, DoctorPracticeRelationship>;

  @Expose()
  @Transform(
    (value) => {
      if (value) {
        let map = new Map<string, DoctorPracticeRelationship>();
        for (let entry of Object.entries(value))
          map.set(entry[0], plainToClass(DoctorPracticeRelationship, entry[1]));
        return map;
      } else {
        return new Map<string, DoctorPracticeRelationship>();
      }
    },
    { toClassOnly: true }
  )
  associatedDoctors: Map<string, DoctorPracticeRelationship>;

  @Expose()
  @Transform(
    (value) => {
      if (value) {
        let map = new Map<string, AssignProfile>();
        for (let entry of Object.entries(value))
          map.set(entry[0], plainToClass(AssignProfile, entry[1]));
        return map;
      } else {
        return new Map<string, AssignProfile>();
      }
    },
    { toClassOnly: true }
  )
  assignProfile: Map<string, AssignProfile>;

  @Expose()
  @Transform(
    (value) => {
      if (value) {
        let map = new Map<string, DocumentInfo>();
        for (let entry of Object.entries(value))
          map.set(entry[0], plainToClass(DocumentInfo, entry[1]));
        return map;
      } else {
        return new Map<string, DocumentInfo>();
      }
    },
    { toClassOnly: true }
  )
  medicalRegistrationProofUrls: Map<string, DocumentInfo> = new Map();

  @Expose()
  @Transform(
    (value) => {
      if (value) {
        let map = new Map<string, DocumentInfo>();
        for (let entry of Object.entries(value))
          map.set(entry[0], plainToClass(DocumentInfo, entry[1]));
        return map;
      } else {
        return new Map<string, DocumentInfo>();
      }
    },
    { toClassOnly: true }
  )
  governmentIdProofUrls: Map<string, DocumentInfo>;

  @Type(() => ProfileLinks)
  @Expose()
  profileLinks: ProfileLinks = new ProfileLinks();

  @Expose()
  @Transform(
    (value) => {
      if (value) {
        let map = new Map<string, ContactNumber>();
        for (let entry of Object.entries(value))
          map.set(entry[0], plainToClass(ContactNumber, entry[1]));
        return map;
      } else {
        return new Map<string, ContactNumber>();
      }
    },
    { toClassOnly: true }
  )
  callExtensions: Map<string, ContactNumber> = new Map();

  @Expose() uniqueTag: string;
  @Expose() showInListing: boolean;
  @Expose() listInGSWT: boolean;
  @Expose() showInSearch: boolean;
  @Expose() showInListingApprovedOn: number;
  @Expose() isProfileVerifiedByDocVita: boolean;
  @Expose() profileVerifiedOn: number;
  @Expose() organisationId: string;
  @Expose() organisationName: string;
  @Expose()
  @Transform(
    (value) => {
      if (value) {
        let map = new Map<string, AssociatedDoctorsWallet>();
        for (let entry of Object.entries(value))
          map.set(entry[0], plainToClass(AssociatedDoctorsWallet, entry[1]));
        return map;
      } else {
        return new Map<string, AssociatedDoctorsWallet>();
      }
    },
    { toClassOnly: true }
  )
  associatedWallet: Map<string, AssociatedDoctorsWallet>;

  @Expose() isZoomAuthVerified:boolean;
  @Expose() isGoogleAuthVerified:boolean;

  @Expose() totalAppointmentsAtDocVita:number;
  @Expose() nextAvailableDate:string;
  @Expose() nextAvailableTime:string;
  @Expose() nextAvailableUpdatedOn:number;
  
  @Expose() bulkPayoutsOnly:boolean;
  @Expose() payoutScheduleComments:string;

  @Type(()=> ActionDoneBy)
  @Expose() scheduleLastUpdatedBy:ActionDoneBy;

  get nextAvailableDateForDisplay() {
    if(this.nextAvailableDate) return new DayjsHelper().YYYYMMDDToEEEMMMd(this.nextAvailableDate);
    return "";
  }

  get genderAddress() {
    if(this.gender) {
      let val = this.gender.toLowerCase();
      switch(val) {
        case "male": {
          return "sir";
        }
        case "female": {
          return "ma'am";  
        }
        default: {
          return "";
        }
      }
    } else {
      return "";
    }
  }

  isDoctor() {
    if (this.type != null) return this.type == "doctor";
    if (this.type == null) return "doctor";
    return false;
  }

  isSpecialist() {
    if (this.type != null) return this.type == "specialist";
    return false;
  }

  isAvailableOnVideoCall() {
    let isAvailable = false;
    if (this.schedule != null) {
      for (let s of this.schedule) {
        if (!isAvailable && s.availableForVideoConsultation) {
          isAvailable = true;
        }
      }
      if (
        this.onlineConsultationConfig == null ||
        this.onlineConsultationConfig.fee == null ||
        this.onlineConsultationConfig.fee <= 0
      ) {
        isAvailable = false;
      }
    }
    return isAvailable;
  }

  public get appointmentDuration(): number {
    if (
      this.onlineConsultationConfig &&
      this.onlineConsultationConfig.appointmentDuration
    ) {
      return this.onlineConsultationConfig.appointmentDuration;
    } else if (
      this.schedule &&
      this.schedule.length > 0 &&
      this.schedule[0].timeSlotLength
    ) {
      return this.schedule[0].timeSlotLength;
    } else {
      return 0;
    }
  }

  isInstantBookingOn() {
    let isAvailable = false;
    if (this.schedule != null) {
      for (let s of this.schedule) {
        if (!isAvailable && s.isInstantBookingOn()) {
          isAvailable = true;
        }
      }
    }
    return isAvailable;
  }

  isAcceptingBookingsOnDocVita(practiceId?: string) {
    let isAvailable = false;
    if (this.schedule != null) {
      for (let s of this.schedule) {
        if (!isAvailable && s.acceptsBookingOnDocVita) {
          if (practiceId != null) {
            if (s.availableAtPractices.has(practiceId)) {
              isAvailable = true;
            }
          } else {
            isAvailable = true;
          }
        }
      }
      return isAvailable;
    }
  }

  isAcceptingInPersonBookingsOnDocVita() {
    let isAvailable = false;
    if (this.schedule != null) {
      for (let s of this.schedule) {
        if (
          !isAvailable &&
          s.acceptsBookingOnDocVita &&
          s.availableAtPractices.size > 0
        ) {
          isAvailable = true;
        }
      }
      return isAvailable;
    }
  }

  isAcceptingOnlineBookingsOnDocVita() {
    let isAvailable = false;
    if (this.schedule != null) {
      for (let s of this.schedule) {
        if (
          !isAvailable &&
          s.acceptsBookingOnDocVita
        ) {
          isAvailable = true;
        }
      }
      return isAvailable;
    }
  }

  isAcceptingCallsOnDocVita() {
    let isAvailable = false;
    if (this.schedule != null) {
      for (let s of this.schedule) {
        if (!isAvailable && s.acceptsCallOnDocVita) {
          isAvailable = true;
        }
      }
    }
    return isAvailable;
  }

  videoCallSchedule(): Schedule[] {
    let array: Schedule[] = [];
    if (this.schedule != null) {
      for (let s of this.schedule) {
        if (s.availableForVideoConsultation) {
          array.push(s);
        }
      }
    }
    return array;
  }

  fullName() {
    if (this.salutation) {
      return (
        (this.salutation.includes(".")
          ? this.salutation.substring(0, this.salutation.length - 1)
          : this.salutation) +
        " " +
        this.name
      );
    }
    return this.name;
  }

  shortEducationDescription() {
    if (this.education && this.education.length > 0) {
      let str = "";
      this.education.forEach((val) => {
        if (str.length == 0) {
          str += val.degree;
        } else {
          str += ", " + val.degree;
        }
      });
      return str;
    }
    return "";
  }

  educationAsASentence() {
    if (this.education && this.education.length > 0) {
      let str = "";
      this.education.forEach((val) => {
        if (str.length == 0) {
          str += val.degree;
        } else if (
          val.degree == this.education[this.education.length - 1].degree
        ) {
          str += " and " + val.degree;
        } else {
          str += ", " + val.degree;
        }
      });
      return str;
    } else {
      return "";
    }
  }

  getClinicFees(id: string) {
    let fee = 0;
    if (this.associatedPractices.has(id)) {
      fee = this.associatedPractices.get(id).fee;
    }
    return fee;
  }

  getOnlineFees() {
    let fee = 0;
    if (
      this.onlineConsultationConfig != null &&
      this.onlineConsultationConfig.fee > 0
    ) {
      fee = this.onlineConsultationConfig.fee;
    }
    return fee;
  }

  getInstantconsultFees() {
    if (
      this.getMasterSpecializationDisplay() != null &&
      this.getMasterSpecializationDisplay().includes("Veterinarian")
    ) {
      return Math.floor(this.getOnlineFees() + 50);
    } else {
      return this.getOnlineFees() + 50;
    }
  }
  getInstantconsultFeesListedPrice() {
    if (
      this.getMasterSpecializationDisplay() != null &&
      this.getMasterSpecializationDisplay().includes("Veterinarian")
    ) {
      return Math.floor(this.getOnlineFees() + 100);
    } else {
      return this.getOnlineFees() + 100;
    }
  }

  getMedicalRegistrationProofUrlsArray() {
    let array: DocumentInfo[] = [];
    if (
      this.medicalRegistrationProofUrls != null &&
      this.medicalRegistrationProofUrls.size > 0
    ) {
      this.medicalRegistrationProofUrls.forEach((val, key) => {
        array.push(val);
      });
    }
    return array;
  }

  getCityForUrl() {
    let cityVal = this.city;
    if (cityVal != null) {
      cityVal = cityVal.toLowerCase();
      cityVal = cityVal.split(" ").join("-");
    }
    return cityVal;
  }

  getLocality() {
    let str = "";
    if (this.practices != null && this.practices.length > 0) {
      for (let p of this.practices) {
        if (str.length == 0) {
          str += p.locality;
        } else {
          str += ", " + p.locality;
        }
      }
    }
    return str;
  }

  getProfileLinkRelativePath() {
    let str = "";
    if (this.uniqueTag != null && this.uniqueTag.length > 0) {
      str +=
       "/" +
        this.getCityForUrl() +
        "/" +
        this.type +
        "/" +
        this.uniqueTag;
    }
    return str;
  }

  getProfileLink() {
    let str = "";
    if (this.uniqueTag != null && this.uniqueTag.length > 0) {
      str +=
        "https://docvita.com/" +
        this.getCityForUrl() +
        "/" +
        this.type +
        "/" +
        this.uniqueTag;
    }
    return str;
  }

  getInviteLink() {
    let str = "";
    if (this.uniqueTag != null && this.uniqueTag.length > 0) {
      str +=
        "https://docvita.com/" +
        this.getCityForUrl() +
        "/" +
        this.type +
        "/" +
        this.uniqueTag +
        "/invite";
    }
    return str;
  }

  displaySpecializations() {
    let array = [];
    if (this.specializations) {
      for (let s of this.specializations) {
        if (!s.toLowerCase().includes("special interest")) {
          array.push(
            specializationDisplayText(s)
          );
        }
      }
    }
    return array;
  }

  displaySpecializationsText() {
    if (this.getMasterSpecialization() == "psychologist") {
      let str = this.transformSpecialtyDisplayText();
      if(this.isRCILicenseVerified) str = str.replace("Clinical Psychologist","Licensed Clinical Psychologist");
      return str;
    } else {
      let array = [];
      if (this.specializations) {
        for (let s of this.specializations) {
          if (!s.toLowerCase().includes("special interest") && !s.toLowerCase().includes("addiction")) {
            array.push(
              specializationDisplayText(s)
            );
          }
        }
      }
      return array.join(", ");
    }
  }

  getSpokenLanguagesSeparatedByComma(): string {
    let str = "";
    if (this.languages != null) {
      this.languages = this.languages.sort((a,b)=>{
        return a.localeCompare(b);
      });
      for (let i = 0; i < this.languages.length; i++) {
        let language = this.languages[i];
        language = language.charAt(0).toUpperCase() + language.slice(1);
        str += language;
        if (i != this.languages.length - 1) {
          str += ", ";
        }
      }
    }
    return str;
  }

  getMasterSpecialization(): string {
    return getMasterSpecialization(this);
  }

  getMasterSpecializationDisplay(): string {
    return getMasterSpecializationDisplayValue(
      this
    );
  }

  getOnlineConsultationTimings() {
    return getOnlineConsultationTimings(this);
  }

  getOfflineConsultationTimings(practiceId: string) {
    return getOfflineConsultationTimings(
      practiceId,
      this
    );
  }

  getFirstNameWithSalutation() {
    let str = getFirstName(this);
    if (this.salutation) {
      return (
        (this.salutation.includes(".")
          ? this.salutation.substring(0, this.salutation.length - 1)
          : this.salutation) +
        " " +
        str
      );
    }
    return str;
  }

  getFirstNameWithoutSalutation() {
    let str = getFirstName(this);
    // if (this.salutation) {
    //   return (
    //     (this.salutation.includes(".")
    //       ? this.salutation.substring(0, this.salutation.length - 1)
    //       : this.salutation) +
    //     " " +
    //     str
    //   );
    // }
    return str;
  }

  getLastNameWithSalutation() {
    let str = getLastName(this);
    if (this.salutation) {
      return this.salutation + " " + str;
    }
    return str;
  }

  @Expose() thumbnailUrl:string;
  get photoUrlForListing(): string {
    return this.thumbnailUrl != null && this.thumbnailUrl.length > 0
    ?this.thumbnailUrl:(this.photoUrl != null && this.photoUrl.length > 0
      ? this.photoUrl
      : "https://default-docvita-images.s3.ap-south-1.amazonaws.com/no-image-available.jpg");
  }

  displayTextForPracticesWithInPersonConsultationBookingOn() {
    return displayTextForPracticesWithInPersonConsultationBookingOn(
      this
    );
  }

  displayTextForPracticesWithInPersonConsultationBookingOnWithOR() {
    return displayTextForPracticesWithInPersonConsultationBookingOn(
      this,
      "or"
    );
  }

  displayTextForPracticesWithInPersonConsultationBookingOff() {
    return displayTextForPracticesWithInPersonConsultationBookingOff(
      this
    );
  }

  displayTextForPracticesWithInPersonConsultationBookingOffWithOR() {
    return displayTextForPracticesWithInPersonConsultationBookingOff(
      this,
      "or"
    );
  }

  getAllContactNumbers(){
    return getAllContactNumbers(this);
  }

  getAllWhatsAppContactNumbers(){
    return getAllWhatsAppContactNumbers(this);
  }

  getPublicContactNumbers() {
    return getPublicContactNumbers(this);
  }

  getPrimaryContactNumbers() {
    return getPrimaryContactNumbers(this);
  }

  getPublicContactNumbersWithoutExtension() {
    return getPublicContactNumbersWithoutExtension(
      this
    );
  }

  getPublicContactNumbersWithExtension() {
    return getPublicContactNumbersWithExtension(
      this
    );
  }

  getContactNumbersWithExtension() {
    return getContactNumbersWithExtension(
      this
    );
  }

  getPrimaryEmail() {
    return getPrimaryEmail(this);
  }

  getSlackEmail() {
    return getSlackEmail(this);
  }
  // // temporary function - assumes there's only one service.
  // // need to be transitioned to handle multiple services.

  // public get bookingServiceTitle(): string {
  //   var serviceTitle: string; // default
  //   if (this.schedule == null || this.schedule.length == 0) {
  //     serviceTitle = "Video Consultation"; // default
  //   } else {
  //     if (this.getMasterSpecialization() == "psychologist") {
  //       serviceTitle = this.schedule[0].timeSlotLength + " min. session";
  //       // serviceTitle = "first session";
  //     } else if (this.getMasterSpecialization() == "psychiatrist") {
  //       serviceTitle = this.schedule[0].timeSlotLength + " min. consultation";
  //     } else if (this.getMasterSpecialization() == "veterinarian") {
  //       serviceTitle = "Online Pet Consultation";
  //     } else {
  //       serviceTitle = "Online Consultation";
  //     }
  //   }
  //   return serviceTitle;
  // }
  

  public get timeSlotLength(): number {
    return this.schedule != null && this.schedule.length != 0
      ? this.schedule[0].timeSlotLength
      : 0;
  }

  public get isPsychologist(): boolean {
    const masterSpecialization = this.getMasterSpecialization()?.toLowerCase();
    return masterSpecialization == "psychologist" || masterSpecialization == "counselling therapist" 
      || masterSpecialization == "life coach"
      || masterSpecialization == "counsellor"
      || masterSpecialization.includes('psychologist');
  }

  public sessionOrAppointment(isTitleCase?:boolean): string {
    const sOrP = this.isPsychologist ? 'session':'appointment';
    if(isTitleCase){
      return sOrP[0].toUpperCase() + sOrP.slice(1);  
    }
    return sOrP;
  }

  public get sessionOrConsultation(): string {
    return this.isPsychologist ? 'session':'consultation';
  }

  public get isClinicalPsychologist(): boolean {
    return this.specializations.includes("Clinical Psychologist")
  }

  public get feeAsString(): string {
    return "₹" + this.getOnlineFees();
  }

  public get markedUpPrice(): string {
    return "₹" + this.getOnlineFees() * 1.2;
  }

  public get shouldDisplayContactSection(): boolean {
    switch (this.getMasterSpecialization()) {
      case "psychologist":
        return true;
      case "dentist":
        return true;
      default:
        return false;
    }
  }

  transformSpecialtyDisplayText() {
    let arr = this.displaySpecializations();
    let map: any = {};
    for (let a of arr) {
      a = a.trim();
      map[a] = true;
    }
    if (map["Counselling Psychologist"] && map["Psychologist"]) {
      delete map["Psychologist"];
    }
    if (map["Clinical Psychologist"] && map["Psychologist"]) {
      delete map["Psychologist"];
    }
    if (map["Applied Psychologist"] && map["Psychologist"]) {
      delete map["Psychologist"];
    }
    if (map["Counselling Therapist"] && map["Psychologist"]) {
      delete map["Psychologist"];
    }
    if (map["Applied Psychologist"] && map["Psychotherapist"]) {
      delete map["Psychotherapist"];
    }
    if (map["Counselling Psychologist"] && map["Psychotherapist"]) {
      delete map["Psychotherapist"];
    }
    if (map["Clinical Psychologist"] && map["Psychotherapist"]) {
      delete map["Psychotherapist"];
    }
    if (map["Vedic Psychologist"] && map["Counselling Psychologist"]) {
      delete map["Counselling Psychologist"];
    }
    if (map["CBT practitioner"]) {
      delete map["CBT practitioner"];
    }
    if (map["Meditation Trainer"]) {
      delete map["Meditation Trainer"];
    }
    if (map["NLP Therapist"]) {
      delete map["NLP Therapist"];
    }
    if (map["Relationship Therapist"]) {
      delete map["Relationship Therapist"];
    }
    if (map["Art Therapist"]) {
      delete map["Art Therapist"];
    }
    if (map["Child Psychologist"]) {
      delete map["Child Psychologist"];
    }
    let arrNew: string[] = [];
    for (let a in map) {
      arrNew.push(a);
    }
    return arrNew.join(", ");
  }

  public get imageAltText(): string {
    return (
      this.fullName() +
      " - " +
      this.getMasterSpecializationDisplay() +
      " - " +
      this.city
    );
  }
}

export function getPrimaryEmail(profile:DoctorListingProfile){
  if(profile.emails) {
    let email:Email = new Email();
    if(profile.emails.size==1){
      profile.emails.forEach(val=>{
        email = val;
      })
    }else{
      profile.emails.forEach(val=>{
        if(val.isPrimary){
          email = val;
        }
      })
    }
    return email;
  }else{
    return null;
  }
}

export function getSlackEmail(profile:DoctorListingProfile){
  if(profile.emails) {
    let email:Email = new Email();
    profile.emails.forEach(val=>{
      if(val.isSlackConnected){
        email = val;
      }
    })
    return email;
  }else{
    return null;
  }
}
